import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Code from '../components/code';
import LittleImg from '../components/LittleImg';
export const _frontmatter = {
  "title": "Introducing Mastery Games",
  "date": "2020-04-07",
  "promo": "grids",
  "description": "The all-new platform for leveling up your coding skills",
  "color": "rgb(47, 77, 199)"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* 
      TODO: update this article with links to the new articles once I have them.
      This will be a blog post for all subs, AND a drip for new subs as they sign up for either flexbox zombies or service workies.
      */
    }
    <p>{`I'm excited to announce the launch of the brand new Mastery Games® platform! This represents many months of hard work and hundreds of git commits. Here's everything that's new and awesome. Enjoy!`}</p>
    <LittleImg width="100px" src="/img/smugduck.jpg" mdxType="LittleImg" />
    <p>{`Heads up if you're planning on mastering CSS Grid at some point: to celebrate this launch `}<a parentName="p" {...{
        "href": "https://gridcritters.com?ref=mastery-games-platform"
      }}>{`Grid Critters`}</a>{` is on a massive 45% off sale!`}</p>
    <h2>{`New Logo`}</h2>
    <p>{`I hired some rando on the internet to make me a logo, and the chump came back with a barely-modified version of a dragon image I had already seen on google photo search. Not cool! So I decided to make the logo myself in `}<a parentName="p" {...{
        "href": "https://figma.com"
      }}>{`Figma`}</a>{`.`}</p>
    <p>{`I wanted it to somehow represent both "code" and "gaming", capturing the feeling of "mastery through play". After many revisions I landed on this little squid creature:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/logo-design.jpg",
        "alt": "mastery games logo design"
      }}></img></p>
    <p>{`His top two tentacles are angle brackets `}<inlineCode parentName="p">{`< >`}</inlineCode>{` like you see in TypeScript types, HTML tags, certain CSS selectors, and JavaScript comparision operators etc.`}</p>
    <p>{`I can't wait to animate this little fella all over the place. He'll make for a pretty entertaining loading screen :)`}</p>
    <h2>{`Everything in One Place`}</h2>
    <p>{`My mastery games were scattered around the web in different places. To play `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`, `}<a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{` and `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` you needed both a Teachable account and a Mastery Games account. Now all the games are in one rad place: `}<a parentName="p" {...{
        "href": "https://mastery.games"
      }}>{`mastery.games`}</a>{` and can be accessed by just your Mastery Games account. Teachable was great for getting started but I outgrew it. It feels so great to now have full control over the UX of my own platform!`}</p>
    <p>{`I've also moved my gedd.ski blog over to this new site.`}</p>
    <h2>{`Accounts & Progress Migrated`}</h2>
    <p>{`The folks at Teachable were awesome and helped make this transition easy. I use `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/auth"
      }}>{`Firebase Auth`}</a>{` (which is awesome) for my new system, and it allows importing of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Bcrypt"
      }}>{`bcrypt`}</a>{` credentials. So everyone is able to login to the new system just like before, and nobody even has to reset their passwords. I was also able to migrate students' progress from Teachable into the new system. Goes down smooth!`}</p>
    <h2>{`Loads o' Polish`}</h2>
    <p>{`All the apps that I find delightful to use (`}<a parentName="p" {...{
        "href": "https://whimsical.com?ref=dg1219"
      }}>{`Whimsical`}</a>{`, `}<a parentName="p" {...{
        "href": "https://figma.com"
      }}>{`Figma`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.notion.so/?r=a8985d7a66844c40b303b28c5b994232"
      }}>{`Notion`}</a>{`, `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/"
      }}>{`VSCode`}</a>{` etc.) have one thing in common: they're `}<em parentName="p">{`constantly`}</em>{` shipping polish. `}</p>
    <p>{`I've written before about `}<a parentName="p" {...{
        "href": "https://mastery.games/post/polish/"
      }}>{`when to polish`}</a>{` and that `}<a parentName="p" {...{
        "href": "https://mastery.games/post/polish-makes-perfect/"
      }}>{`polish makes perfect`}</a>{`. Constant polish is what transforms a decent product into a masterpiece.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/new-menu.jpg",
        "alt": "new levels menu"
      }}></img></p>
    <p>{`Some of the new polish I've shipped in this new platform includes:`}</p>
    <ul>
      <li parentName="ul">{`a fader component for smooth transitions between levels`}</li>
      <li parentName="ul">{`a new levels menu that shows your current progress`}</li>
      <li parentName="ul">{`a quick navigation menu between the games`}</li>
      <li parentName="ul">{`simple music & sound effect controls`}</li>
      <li parentName="ul">{`new game home, chapter intro, and chapter end screens with additional resources`}</li>
      <li parentName="ul">{`refactored a ton of logic to use `}<a parentName="li" {...{
          "href": "https://mastery.games/post/state-machines-in-react/"
        }}>{`state machines`}</a></li>
      <li parentName="ul">{`lots of other little bug fixes and UX refinements`}</li>
    </ul>
    <h2>{`Monorepo`}</h2>
    <p>{`On the tech side of things I've merged my games into a single git `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Monorepo"
      }}>{`monorepo`}</a>{`. I'll write more details about this in another post, but I'm really excited about the benefits I'm already seeing. The games are now using many shared components and utils. I'm confident this is going to really speed up the creation of new mastery games.`}</p>
    <h2>{`Theming`}</h2>
    <p>{`As each mastery game has its own unique feel and art, I needed a way to theme the shared components. It ended up being a lot easier than I thought, and I'll share my learnings about it in a future post.`}</p>
    <h2>{`Lighthouse CI`}</h2>
    <p>{`I've got `}<a parentName="p" {...{
        "href": "https://github.com/GoogleChrome/lighthouse-ci"
      }}>{`Lighthouse CI`}</a>{` setup to run on each pull request that I make. I'm using `}<a parentName="p" {...{
        "href": "https://github.com/features/actions"
      }}>{`github actions`}</a>{` to do it and the result is pretty sweet. But between you and me, my lighthouse scores are `}<em parentName="p">{`terrible`}</em>{`. My games and blog are heavy on the media usage. But at least now I've got a way to measure and know when an effort helps. I'll make a "journey to excellent lighthouse scores" blog post and share everything that I do to fix it.`}</p>
    <h2>{`TypeScript`}</h2>
    <p>{`I've embraced `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{` and am converting JS files to it as I go. TypeScript is one of those things that you really only benefit from once the vast majority of your code is in TS. The incremental types story is helpful for existing projects but you don't actually see many gains until you're done converting everything. I'm paying the cost now in terms of extra work to make the TS compiler happy, but I'm excited about what it will do for my projects. Especially where I'm now sharing so much code, it's going to be way nice to know in advance if a code change breaks one of the games.`}</p>
    <h2>{`Room to Grow`}</h2>
    <p>{`This new platform lays the groundwork for more features and the building of many more mastery games. I won't have to start from scratch with each new game. Creating these experiences to share what I know with you is my life's passion, and I'm eager to begin on the next one.`}</p>
    <LittleImg width="200px" src="/img/ai.jpg" mdxType="LittleImg" />
    <p>{`Thanks for reading!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      